// Extensions Module
// 

// Starter
import homeRoutes       from '@/extensions/modules/starter/home/router'

// Multiverse
import originRoutes     from '@/extensions/modules/multiverse/origin/router'
import assetRoutes      from '@/extensions/modules/multiverse/asset/router'
import collectionRoutes from '@/extensions/modules/multiverse/collection/router'
import clientRoutes from '@/extensions/modules/multiverse/client/router'
import spaceRoutes from '@/extensions/modules/multiverse/space/router'
import resourceRoutes from '@/extensions/modules/multiverse/resource/router'

// E-Learning
import enrollRoutes     from '@/extensions/modules/elearning/enroll/router'
import schoolRoutes     from '@/extensions/modules/elearning/school/router'
import studentRoutes    from '@/extensions/modules/elearning/student/router'
import lessonRoutes     from '@/extensions/modules/elearning/lesson/router'
import categoryRoutes   from '@/extensions/modules/elearning/category/router'
import seminarRoutes    from '@/extensions/modules/elearning/seminar/router'

// E-Commerce
import shopRoutes       from '@/extensions/modules/ecommerce/shop/router'
import orderRoutes      from '@/extensions/modules/ecommerce/order/router'
import billingRoutes    from '@/extensions/modules/ecommerce/billing/router'
import marketplaceRoutes    from '@/extensions/modules/ecommerce/marketplace/router'
import branchRoutes     from '@/extensions/modules/ecommerce/branch/router'
import websiteRoutes    from '@/extensions/modules/ecommerce/website/router'
import walletRoutes    from '@/extensions/modules/ecommerce/wallet/router'
import redeemRoutes    from '@/extensions/modules/ecommerce/redeem/router'

// Blog
import cmsRoutes        from '@/extensions/modules/blog/cms/router'

// System
import appointmentRoutes      from '@/extensions/modules/system/appointment/router'
import supportRoutes      from '@/extensions/modules/system/livesupport/router'

import noteRoutes      from '@/extensions/modules/system/note/router'
import configRoutes     from '@/extensions/modules/system/config/router'
import setupRoutes      from '@/extensions/modules/system/setup/router'
import installRoutes    from '@/extensions/modules/system/install/router'
import memberRoutes     from '@/extensions/modules/system/member/router' //Backend Member
import userRoutes     from '@/extensions/modules/system/user/router'

import debugRoutes     from '@/extensions/modules/system/debug/router'
import ticketRoutes     from '@/extensions/modules/system/ticket/router'
// Untitle
import messageRoutes    from '@/extensions/modules/untitle/message/router'
import testRoutes       from '@/extensions/modules/untitle/test/router'

// Application
import institutionRoutes       from '@/extensions/modules/standard/institution/router'
import applicationRoutes       from '@/extensions/modules/standard/application/router'
import formRoutes       from '@/extensions/modules/standard/form/router'
import applyRoutes       from '@/extensions/modules/standard/apply/router'


// Extra
import bqclp_access       from '@/extensions/modules/extra/bqclp/access/router'
import bqclp_create       from '@/extensions/modules/extra/bqclp/create/router'
import bqclp_request      from '@/extensions/modules/extra/bqclp/request/router'
import bqclp_send         from '@/extensions/modules/extra/bqclp/send/router'
import bqclp_status       from '@/extensions/modules/extra/bqclp/status/router'
import bqclp_website       from '@/extensions/modules/extra/bqclp/website/router'

// Vote
import eventRoutes       from '@/extensions/modules/extra/vote/router'

const pluginRoutes = [
  ...homeRoutes,
  ...applyRoutes,
  ...originRoutes,
  ...clientRoutes,
  ...spaceRoutes,
  ...resourceRoutes,
  ...institutionRoutes,
  ...applicationRoutes,
  ...formRoutes,
  ...enrollRoutes,
  ...lessonRoutes,
  ...orderRoutes,
  ...shopRoutes,
  ...walletRoutes,
  ...redeemRoutes,
  ...billingRoutes,
  ...branchRoutes,
  ...messageRoutes,
  ...marketplaceRoutes,
  ...userRoutes,
  ...debugRoutes,
  ...memberRoutes,
  ...eventRoutes,
  ...schoolRoutes,
  ...categoryRoutes,
  ...websiteRoutes,
  ...collectionRoutes,
  ...studentRoutes,
  ...seminarRoutes,
  ...cmsRoutes,
  ...bqclp_create,
  ...bqclp_request,
  ...bqclp_send,
  ...bqclp_status,
  ...bqclp_website,
  ...bqclp_access,
  ...appointmentRoutes,
  ...noteRoutes,
  ...configRoutes,
  ...setupRoutes,
  ...testRoutes,
  ...assetRoutes,
  ...installRoutes,
  ...supportRoutes,
  ...ticketRoutes,
]

export default pluginRoutes