<script>
import { onBeforeRouteUpdate } from 'vue-router';
import { setLanguage, translate, getAllLanguages, getCurrentLanguage } from '@/plugins/language.js';
import VueAvatar from "@webzlodimir/vue-avatar";
import "@webzlodimir/vue-avatar/dist/style.css";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

import pluginRoutes from '@/extensions/loader';
import { getPluginRoutes, getPluginNames } from '@/plugins/UtilsLoader';
import storageManager from '@/plugins/storage';

import MarqueeText from './Marquee.vue';

import requestClient from '@/plugins/requestClient';
const Request = new requestClient(false);

import debug from '@/plugins/Logger.js';
const storedConfigData = storageManager.get('configs');

const pluginNames = getPluginNames(pluginRoutes);
const routesWithSubmenus = getPluginRoutes(pluginRoutes);

const pluginNamesStorage = {
  data: pluginNames,
  timestamp: new Date().getTime(),
};
storageManager.set('plugins', pluginNamesStorage);

const storedPluginNames = storageManager.get('plugins');
const oneHour = 60 * 60 * 1000;
const now = new Date().getTime();

if (!storedPluginNames || now - storedPluginNames.timestamp >= oneHour) {
  const pluginNames = getPluginNames(pluginRoutes);
  const newPluginNamesStorage = {
    data: pluginNames,
    timestamp: now,
  };
  storageManager.set('plugins', newPluginNamesStorage);
}

function buildMenuItems(routes, parent, showParent) {
  if (!routes) {
    return [];
  }

  return routes.reduce((acc, route) => {
    if (route.meta.inMenu) {
      const menuItem = {
        name: route.meta.title,
        href: `/${parent}/${route.path}`,
        icon: route.meta.icon,
        role: route.meta.role,
        language: translate('router-' + route.meta.parent + '-' + route.meta.key),
        current: false,
        counter: false,
        session: route.session || false,
        front: route.meta.inFront || false,
        submenu: null,
      };
      if (showParent && route.children && route.children.length > 0) {
        menuItem.submenu = buildMenuItems(route.children, `${parent}/${route.path}`, true);
      }
      acc.push(menuItem);
    } else if (showParent && acc.length > 0 && acc[acc.length - 1].submenu) {
      acc[acc.length - 1].submenu.pop();
    }
    return acc;
  }, []);
}
export default {
  inject: ['config', 'saltSecret'],
  components: {
    VueAvatar,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MarqueeText
  },
  data() {
    return {
      isMobileMenuOpen: false,
      expandedSubmenuIndex: null,
      // Translate
      current:'',
      languages: [],
      // Translate
      configData: {},
      isOpen: false,
      navigation: this.navigation,
      configs: storageManager.get('configs'),
      session: storageManager.get('session'),
      token: storageManager.get('session', 'token'),
      login: storageManager.get('session', 'login'),
      nav: storageManager.get('session', 'nav'),
      user: storageManager.get('session', 'user'),
      role: storageManager.get('session', 'role'),
      menu: null,
      className: 'bg-indigo-900 border-b border-gray-200',
      logoSrc: 'https://vue-project.sgp1.digitaloceanspaces.com/Logo/white-logopng.png',
      count: {
        order: 0,
        message: 0
      },
      selectedLanguage: { code: '', name: '', flag: '' },
      showSubMenu: false,
      userNavigation: [
        { name: this.translate('member-profile'), href: '/user/profile' },
        { name: this.translate('member-config'), href: 'javascript:void(0);' },
        { name: this.translate('member-console'), click: this.openNewWindow },
        { name: this.translate('member-logout'), href: 'javascript:void(0);' }
      ]
      };
  },
  created() {
    this.className = this.config.siteStyle;
    this.logoSrc = this.config.siteLogo;
    this.siteName = this.config.siteName;
    // Translate
    this.languages = getAllLanguages();
    this.current = getCurrentLanguage();
    this.selectedLanguage = this.languages.find(language => language.code === this.current);
    // Translate
  },
  setup() {
    onBeforeRouteUpdate((to, from, next) => {
      this.handleRouteChange(to, from, next);
    });

    return {};
  },
  async mounted() {
    await this.countData();
    
    const rowRaw = storageManager.get('session', 'role');
    const roleDecrypt = rowRaw;
    const pluginData = storedConfigData && storedConfigData.plugins ? storedConfigData.plugins : [];

    const mainRoutes = [].concat(
      routesWithSubmenus
        .filter(routeWithSubmenu => {
          const hasValidRole = !routeWithSubmenu.role || routeWithSubmenu.role.includes(roleDecrypt);
          return routeWithSubmenu.meta.inMenu && pluginData.includes(routeWithSubmenu.slug) && hasValidRole;
        })
        .map(routeWithSubmenu => {
          return {
            slug: routeWithSubmenu.slug,
            name: routeWithSubmenu.name,
            language: translate('router-' + routeWithSubmenu.meta.parent + '-' + routeWithSubmenu.meta.key),
            href: routeWithSubmenu.path,
            icon: routeWithSubmenu.icon,
            inApp: routeWithSubmenu.inApp,
            inTop: routeWithSubmenu.inTop,
            counter: routeWithSubmenu.counter,
            hasSubmenu: routeWithSubmenu.hasSubmenu,
            session: routeWithSubmenu.session,
            fullscreen: routeWithSubmenu.fullscreen,
            current: false,
            submenu: buildMenuItems(routeWithSubmenu.routes, routeWithSubmenu.slug),
          };
        })
    );

    for (const route of mainRoutes) {
      if (route.counter) {
        try {
          let routerCount = 0; 
          if (route.href === "/message") {
            routerCount = this.count.message
            //debug.log("href", route.href);
          } else if (route.href === "/enroll") {
            routerCount = this.count.order
            //debug.log("href", route.href);
          }
          route.counter = routerCount > 99 ? '99+' : routerCount;
        } catch (error) {
          debug.error(`Failed to fetch counter for ${route.name}`, error);
        }
      }
    }
    const filteredRoutes = mainRoutes.filter(Top => Top.inTop === 'yes');
    this.navigation = filteredRoutes;

    const userRole = storageManager.get('session', 'role'); // Replace this with the actual user's role
    if(userRole==='manager') {
      this.navigation.forEach((item) => {
        if (item.submenu) {
          item.submenu = item.submenu.filter((submenuItem) =>
            submenuItem.role.includes(userRole)
          );
        }
      });
      this.navigation = this.navigation.filter((item) =>
        item.submenu ? item.submenu.length > 0 : true
      );
    }
    storageManager.set('navigation', mainRoutes);
  },
  methods: {
    translate,
    toggleSubMenu() {
      this.showSubMenu = !this.showSubMenu;
    },
    closeSubMenu() {
      this.showSubMenu = false;
    },
    navigate(to) {
      this.$router.push(to);
      this.closeSubMenu();
    },
    async selectLanguage(language) {
      this.selectedLanguage = language;
      this.showSubMenu = false;
      await setLanguage(language.code);
    },
    async changeLanguage(event) { 
      const selectedLanguageCode = event.target.value;
      console.log("Selected Language Code:", selectedLanguageCode);
      await setLanguage(selectedLanguageCode);
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    toggleSubmenu(index) {
      this.expandedSubmenuIndex = this.expandedSubmenuIndex === index ? null : index;
    },
    menuNameTranslate(name) {
      if (typeof name === 'object' && name[this.current]) {
        return name[this.current];
      } else if (typeof name === 'string') {
        return name;
      } else {
        return 'Undefined';
      }
    },
    openNewWindow() {
      window.open('debug/display', '_blank', 'width=500,height=600,location=no,status=no');
    },
    async countData() {
      try {
        const pipeline = [
          {
            $match: {
              unit: this.session.current._id,
              status: 'ลงทะเบียนคอร์ส'
            }
          },
          {
            $group: {
              _id: null,
              orderCount: { $sum: 1 },
            }
          },
          {
            $project: {
              _id: 0,
              orderCount: 1,
            }
          },
          {
            $unionWith: {
              coll: 'message',
              pipeline: [
                {
                  $match: {
                    parent: this.session.current._id,
                    status: 'submit'
                  }
                },
                {
                  $group: {
                    _id: null,
                    messageCount: { $sum: 1 },
                  }
                },
                {
                  $project: {
                    _id: 0,
                    messageCount: 1,
                  }
                }
              ],
            }
          }
        ];
        const { status, data } = await Request.POST('order/aggregate', { pipeline }, this.configs.key);
        if (status === 200) {
          const resultArray = Array.isArray(data) ? data : [data];
          const result0 = resultArray.find(item => item.orderCount !== undefined);
          const result1 = resultArray.find(item => item.messageCount !== undefined);
          this.count = {
            order: result0?.orderCount || 0,
            message: result1?.messageCount || 0,
          };
          debug.log(this.count);
        }
      } catch (error) {
        debug.log(error);
      }
    },
    logout() {
      this.$swal({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 500,
        icon: 'success',
        title: 'แจ้งเตือน',
        text: 'ออกจากระบบเรียบร้อยแล้ว',
      }).then(() => {
        storageManager.delete('session');
        storageManager.delete('hostname');
        window.location.href = '/member/login';
      });
    },
    updateRouteCounters(route) {
      debug.log("Update Router")
      for (const item of this.navigation) {
        if (item.href === route.path && item.counter) {
          this.countRouter(item.href)
            .then(routerCount => {
              item.counter = routerCount > 99 ? '99+' : routerCount;
            })
            .catch(error => {
              debug.error(`Failed to fetch counter for ${item.name}`, error);
            });
        }
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.updateRouteCounters(to);
    next();
  },
};
</script>

<template>

<div v-if="!$route.meta.fullscreen">
  <div class="bg-[#151a33] ">
    <div class="mx-auto px-4 sm:px-6 lg:px-8 ">
      <MarqueeText class="text-white text-sm py-1" :text="translate('general-marquee')	" duration="30" />
    </div>
  </div>
  <Disclosure as="nav" :class="className" v-slot="{  }" v-if="configData">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        
        <div class="flex items-center">

          <div>
            <!-- Hamburger Button -->
            <button @click="toggleMobileMenu" class="lg:hidden p-1">
              <font-awesome-icon :icon="['fas', 'bars']" class="text-2xl text-white"/>
            </button>
        
            <!-- Mobile Menu Panel -->
            <div :class="['mobile-menu', { 'open': isMobileMenuOpen }]">
              <div class="header-container">
                <span class="site-name">
                  {{ siteName }}<br/>
                  <span class="text-xs text-gray-400 font-normal">{{ session.current.siteName }}</span>
                </span>
                
                <button @click="toggleMobileMenu" class="close-btn">
                  <font-awesome-icon :icon="['fas', 'times']" class="text-lg"/>
                </button>
              </div>
              <div class="menu-content">
                <div v-for="(item, index) in navigation" :key="item.href" class="py-2">
                  <button @click="toggleSubmenu(index)" class="menu-item w-full text-left">
                    <span class="flex items-center justify-between grow pr-2">
                      <div class="flex items-center">
                          <font-awesome-icon :icon="['fas', item.icon]" class="mr-2" />
                          <span class="font-semibold">{{ item.language }}</span>
                          <span v-if="item.counter" class="nav-counter">{{ item.counter }}</span>
                      </div>
                      <font-awesome-icon :icon="['fas', 'chevron-down']" class="chevron-icon text-gray-400 text-sm" :class="{'rotate-180': expandedSubmenuIndex === index}" />
                  </span>
                  </button>
                  <div v-if="item.hasSubmenu && expandedSubmenuIndex === index" class="submenu-container">
                    <button v-for="submenuItem in item.submenu" :key="submenuItem.href" 
                            @click.prevent="navigate(submenuItem.href); expandedSubmenuIndex = null" 
                            class="w-full text-left submenu-item text-gray-600">
                      {{ submenuItem.language }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="language-selection">
                <div class="current-language text-gray-400">{{ current }}</div>
                <div class="language-buttons">
                  <button v-for="language in languages" :key="language.code" @click="selectLanguage(language)" class="language-button">
                    <img :src="language.flag" alt="Language Flag" class="language-flag">{{ language.code }}
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="flex-shrink-0 main-logo">
            <button @click.prevent="navigate('/')">
              <img class="h-10 w-auto" :src="logoSrc" :alt="siteName" />
            </button>
          </div>

          <div class="hidden lg:ml-10 lg:block">
            <div class="flex space-x-1">
              <template v-for="item in navigation">
                <button v-if="!item.hasSubmenu" :key="item.href" @click.prevent="navigate(item.href)" :class="[item.current ? 'bg-gray-100' : 'w-full text-left hover:bg-gray-100 hover:text-indigo-900', 'relative px-3 py-2 rounded-md text-sm font-medium text-white']" :aria-current="item.current ? 'page' : undefined">
                  <font-awesome-icon :icon="['fas',item.icon]"/> {{ item.language }}
                  <span v-if="item.counter" class="nav-counter">{{item.counter}}</span>
                </button>
          
                <div v-if="item.hasSubmenu" :key="item.href">
                  <Menu as="div" class="relative">
                    <MenuButton class="ml-2 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-gray-100 hover:text-indigo-900">
                      <font-awesome-icon :icon="['fas',item.icon]"/> {{ item.language }}
                      <span v-if="item.counter" class="nav-counter">{{item.counter}}</span>
                      <font-awesome-icon :icon="['fas','angle-down']" class="ml-1"/>
                    </MenuButton>
                    <MenuItems class="pt-2 pl-2 pr-2 pb-2 absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem v-for="submenuItem in item.submenu" :key="submenuItem.href">
                        <button @click.prevent="navigate(submenuItem.href)" :style="{ 'padding-left': '0.75rem' }" class="w-full text-left block py-2 px-4 text-sm text-gray-700 hover:rounded-md hover:bg-indigo-100 hover:text-indigo-900"><font-awesome-icon :icon="['fas',submenuItem.icon]"/> {{ submenuItem.language }}</button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </template>

              

              <!-- <div class="ml-2">

                  <div class="flex flex-wrap gap-2 mt-[5px] text-[13px] text-white">
                    <button v-for="language in languages" :key="language.code" @click="changeLanguage(language.code)" class="flex items-center justify-center gap-2" :class="{ 'opacity-50': language.code === current, 'opacity-100': language.code !== current }">
                      <img :src="language.flag" alt="Language Flag" class="w-5 h-5">
                      <span class="pt-[3px]">{{ language.code }}</span>
                    </button>                    
                  </div>

              </div> -->
              
            </div>

          </div>

        </div>

        <div class="flex lg:hidden">
          <Menu as="div" class="relative mt-1 flex-shrink-0">
            <div>
              <MenuButton class="flex rounded-full bg-gray-50 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                <span class="sr-only">Open user menu</span>
                <vue-avatar 
                :img-src="''" 
                img-alt="Some image alt" 
                :size="33" class="h-8 w-8 rounded-full" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <a 
                    :href="item.href ? item.href : 'javascript:void(0);'" 
                    @click="item.click && item.click()" 
                    :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                    {{ item.name }}
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>

          <div class="ml-1 relative">
            <div @click="toggleSubMenu" class="z-10 relative px-3 py-2 rounded-md text-sm font-medium text-white bg-transparent flex items-center cursor-pointer hover:bg-gray-50 hover:bg-opacity-20 hover:rounded-sm">
              <img :src="selectedLanguage.flag" alt="Language Flag" class="w-5 h-5 mr-2">
              {{ selectedLanguage.code }} <font-awesome-icon :icon="showSubMenu ? ['fas', 'caret-up'] : ['fas', 'caret-down']" class="text-gray-200 pl-3" />
            </div>                
            <ul v-if="showSubMenu" class="z-50 absolute top-10 left-0 bg-white border border-gray-300 rounded-sm py-1">
              <li v-for="language in languages" :key="language.code" @click="selectLanguage(language)" class="pl-3 pr-6 py-2 flex items-center cursor-pointer text-gray-700 hover:bg-gray-100" :class="{ 'opacity-50': language.code === current, 'opacity-100': language.code !== current }">
                <img :src="language.flag" alt="Language Flag" class="w-3 h-3 mr-1"> <span class=" text-xs">{{ language.name }}</span>
              </li>
            </ul>
            <div v-if="showSubMenu" @click="closeSubMenu" class="z-20 fixed bg-opacity-0 bg-black w-full h-full top-0 left-0"></div>
          </div>
          
          <DisclosureButton class="inline-flex items-center justify-center rounded-md text-gray-white">
            <span class="sr-only">Open main menu</span>
            <button @click="logout" type="button" class="pt-2 ml-1 flex-shrink-0 rounded-full bg-transparent p-1 text-white hover:text-gray-500">
              <font-awesome-icon class="h-6 w-6" aria-hidden="true" :icon="['fas','power-off']"/>
            </button>
          </DisclosureButton>
        </div>

        <!-- Actions section -->
        <div class="hidden lg:ml-4 lg:block">
          <div class="flex items-center">
            
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3 flex-shrink-0">
              <div>
                <MenuButton class="flex rounded-full bg-gray-50 text-sm text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                  <span class="sr-only">Open user menu</span>
                  <vue-avatar 
                  :img-src="''" 
                  img-alt="Some image alt" 
                  :size="33" class="h-10 w-10 rounded-full" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a 
                      :href="item.href ? item.href : 'javascript:void(0);'" 
                      @click="item.click && item.click()" 
                      :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                      {{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>

            <div class="ml-2 relative">
              <div @click="toggleSubMenu" class="z-10 relative px-3 py-2 rounded-md text-sm font-medium text-white bg-transparent flex items-center cursor-pointer hover:bg-gray-50 hover:bg-opacity-20 hover:rounded-sm">
                <img :src="selectedLanguage.flag" alt="Language Flag" class="w-5 h-5 mr-2">
                {{ selectedLanguage.code }} <font-awesome-icon :icon="showSubMenu ? ['fas', 'caret-up'] : ['fas', 'caret-down']" class="text-gray-200 pl-3" />
              </div>                
              <ul v-if="showSubMenu" class="z-50 absolute top-10 left-0 bg-white border border-gray-300 rounded-sm py-1">
                <li v-for="language in languages" :key="language.code" @click="selectLanguage(language)" class="pl-3 pr-6 py-2 flex items-center cursor-pointer text-gray-700 hover:bg-gray-100" :class="{ 'opacity-50': language.code === current, 'opacity-100': language.code !== current }">
                  <img :src="language.flag" alt="Language Flag" class="w-3 h-3 mr-1"> <span class=" text-xs">{{ language.name }}</span>
                </li>
              </ul>
              <div v-if="showSubMenu" @click="closeSubMenu" class="z-20 fixed bg-opacity-0 bg-black w-full h-full top-0 left-0"></div>
            </div>

            <button @click="logout" type="button" class="pt-2 ml-2 flex-shrink-0 rounded-full bg-transparent p-1 text-white hover:text-gray-500">
              <span class="sr-only">ออกจากระบบ</span>
              <font-awesome-icon class="h-6 w-6" aria-hidden="true" :icon="['fas','power-off']"/>
            </button>

          </div>
        </div>

      </div>
    </div>

    <DisclosurePanel class="border-b border-gray-200 bg-gray-50 lg:hidden">

      <div class="space-y-1 px-2 pt-2 pb-3">
        <template v-for="item in navigation">
          <button v-if="!item.submenu" :key="item.href" @click.prevent="navigate(item.href)" :class="[item.current ? 'bg-gray-100' : 'hover:bg-gray-100', 'w-full text-left block relative px-3 py-2 rounded-md font-medium text-gray-900']" :aria-current="item.current ? 'page' : undefined">
            {{ item.language }} <span v-if="item.counter" class="nav-counter">{{item.counter}}</span>
          </button>
      
          <div v-if="item.submenu" :key="item.href">
            <div class="flex items-center justify-between px-3 py-2 font-medium text-gray-900 bg-gray-100 cursor-pointer" @click="item.expanded = !item.expanded">
              <span>{{ item.language }}</span>
              <font-awesome-icon :icon="['fas', item.expanded ? 'angle-up' : 'angle-down']" class="w-4 h-4"></font-awesome-icon>
            </div>
      
            <div v-if="item.expanded" class="space-y-1 px-2 pt-2 pb-3">
              <button v-for="submenuItem in item.submenu" :key="submenuItem.href" @click.prevent="navigate(submenuItem.href)" class="w-full text-left block px-3 py-2 rounded-md font-medium text-gray-900 hover:bg-gray-100">
                {{ submenuItem.language }}
              </button>
            </div>
          </div>
        </template>
      </div>
      
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <vue-avatar :username="storageManager.get('session','token')" :size="50" class="h-10 w-10 rounded-full" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
            <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
          </div>
          
          <button @click="logout" type="button" class="ml-auto flex-shrink-0 rounded-full bg-gray-50 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50">
            <span class="sr-only">ออกจากระบบ</span>
            <font-awesome-icon class="h-6 w-6" aria-hidden="true" :icon="['fas','power-off']"/>
          </button>
        </div>
        <div class="mt-3 space-y-1 px-2">
          <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.href" class="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100">{{ item.language }}</DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</div>
</template>

<style scoped>
.nav-counter {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 10px;
  background: #ff0000;
  border-radius: 50%;
  color: #fff;
}

.nav-counter:hover {
  color: #fff;
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 100%;
  max-width: 100%;
  background: white;
  transition: left 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-right: solid 1px #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Drop shadow */
}

.mobile-menu.open {
  left: 0;
}

.menu-content {
  padding: 0px 0px 0px 5px;
  overflow-y: auto;
  flex: 1;
}

.menu-item, .submenu-item, .close-btn {
  padding: 0.5rem;
}

.menu-item {
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.submenu-item {
  display: block;
  margin: 0.5rem 0;
  padding-left: 1rem;
}

.submenu-item:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
}

.language-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  border-top: solid 1px #eee;
}

.current-language {
  /* Ensures the current language text aligns to the left */
  flex: 1;
}

.language-buttons {
  /* Aligns language buttons to the right */
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping */
  gap: 10px; /* Adjust gap between buttons as needed */
}

.language-button {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.language-flag {
  width: 20px;
  height: 20px; /* Adjust size of flag as needed */
  margin-right: 5px; /* Space between flag and language code */
}

.header-container {
  display: flex;
  justify-content: space-between; /* Aligns items on the main-axis */
  align-items: center; /* Aligns items on the cross-axis */
  padding: 0.5rem; /* Add some padding around the elements */
  border-bottom: solid 1px #eee;
}

.site-name {
  font-weight: bold; /* Optional: Makes the site name bold */
  font-size: 1.25rem; /* Optional: Adjust the font size as needed */
}

.close-btn {
  padding: 0.5rem; /* Add padding for easier tap targets */
  margin: -0.5rem; /* Adjust margin to align button properly */
  padding-right: 20px;
}

</style>
